import React from "react";
import { useParams } from "react-router";
import Query from "../../components/Query";

import ReactMarkdown from "react-markdown";

import ARTICLE_SLUG_QUERY from "../../queries/article/article_slug";

// import { Link } from "react-router-dom";

// Assets
import B18 from "../../assets/B18.jpg";

function NewArticle() {
  let { slug } = useParams();
  console.log(slug);
  const finalSlug = "como-se-obtiene-el-aluminio";
  // const finalSlug = slug.toString();
  return (
    <Query query={ARTICLE_SLUG_QUERY} id={slug}>
      {({ loading, error, data: { articles } }) => {
        console.log(articles);
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error: {error.message}</p>;
        return (
          <div className="blog-article">
            <div className="blog-article__media">
              <div>
                <div className="blog-article__media__title">
                  <h1>
                    <ReactMarkdown
                      source={articles[0].titulo}
                      // renderers={renderers}
                      escapeHtml={false}
                    />
                  </h1>
                </div>
                <img
                  src={articles[0].imagen.url}
                  className="img-fluid"
                  alt="Prominox"
                />
              </div>
            </div>
            <div className="blog-article__body">
              <ReactMarkdown
                source={articles[0].content}
                // renderers={renderers}
                escapeHtml={false}
              />
              {/* <p>
                Para limpiar el acero inoxidable y mantenerlo en buen estado,
                puedes seguir los siguientes pasos:
              </p>
              <p>
                Reúne los materiales: Necesitarás agua tibia, detergente suave,
                un paño suave o una esponja no abrasiva y un paño de microfibra
                o un paño de algodón.
              </p>
              <ol>
                <li>
                  <span>
                    {" "}
                    Limpieza inicial: Puedes comenzr limpiando la superficie de
                    acero inoxidable con agua tibia y un detergente suave.
                    Aplica el detergente en el paño o esponja y frota suavemente
                    la superficie en la dirección del grano del acero. Evita
                    utilizar limpiadores abrasivos o estropajos duros que puedan
                    rayas la superficie.
                  </span>
                </li>
                <li>
                  <span>
                    {" "}
                    Enjuage y seque: Después de limpiar, enjuaba bien la
                    superficie con agua limpia para eliminar cualquier residuo
                    de detergente. A continuación, seca la superficie con un
                    paño de microfibra o un paño de algodón limpio y seco. Esto
                    ayudará a prevenir manchas de agua o marcas de secado.
                  </span>
                </li>
                <li>
                  <span>
                    Eliminación de manchas y marcas: Si encuentras manchas o
                    marcas más difíciles de eliminar, puedes utilizar algunas
                    opciones adicionales de limpieza. Una mezcla de agua y
                    vinage blanco en partes iguales puede ser efectiva para
                    eliminar manchas o huellas dactilares persistentes. También
                    puedes utilizar un limpiador específico para acero
                    inoxidable, siguiendo las instrucciones del fabricante.
                  </span>
                </li>
              </ol> */}
            </div>
          </div>
        );
      }}
    </Query>
  );
}

export default NewArticle;
