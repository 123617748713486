import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";

// Components
import Articles from "../../components/Articles";
import { CategoryJumbotron } from "../../components/Jumbotron/Jumbotron";
import Query from "../../components/Query";
import Contact from "../../components/Contact/Contact";

// Custom styles
import "../Category/category.styles.scss";

// Queries
import ARTICLES_QUERY from "../../queries/category/allArticles";

// Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Media Assets
import benefit1IconUrl from "../../assets/Group.jpg";
import B11 from "../../assets/B11.jpg";
import B10 from "../../assets/B10.jpg";
import B13 from "../../assets/B13.png";
import B14 from "../../assets/B14.png";
import B15 from "../../assets/B15.png";
import TuboConduit from "../../assets/tubo-conduit-blogv2.png";

const NewArticles = () => {
  return (
    <Query query={ARTICLES_QUERY}>
      {({ data: { articles } }) => {
        // console.log(articles);
        const featuredArticles = articles
          .sort((a, b) => a.prioridad - b.prioridad)
          .slice(0, 3);
        const otherArticles = articles.slice(3);
        console.log(featuredArticles);
        return (
          <div>
            <div className="category--v2">
              <div className="category--v2__articles">
                <Container>
                  <Link to={`/blogv2/articulo/${featuredArticles[0].slug}`}>
                    <div
                      className="primary-article"
                      style={{
                        backgroundImage: `url(${featuredArticles[0].fondo.url})`,
                      }}
                    >
                      <div className="primary-article__content">
                        <h1>
                          <ReactMarkdown
                            source={featuredArticles[0].titulo}
                            escapeHtml={false}
                          />
                        </h1>
                        <p>{featuredArticles[0].descripcion}</p>
                      </div>
                    </div>
                  </Link>
                  <Row className="mt-2">
                    <Col xs="6" className="pr-lg-0">
                      <Link to={`/blogv2/articulo/${featuredArticles[1].slug}`}>
                        <div
                          className="secondary-article"
                          style={{
                            backgroundImage: `url(${featuredArticles[1].fondo.url})`,
                          }}
                        >
                          <div className="layer">
                            <p>{featuredArticles[1].descripcion}</p>
                          </div>
                          <div className="secondary-article__heading">
                            <h3>
                              {" "}
                              <ReactMarkdown
                                source={featuredArticles[1].titulo}
                                escapeHtml={false}
                              />
                            </h3>
                          </div>
                        </div>
                      </Link>
                    </Col>
                    <Col xs="6">
                      <Link to={`/blogv2/articulo/${featuredArticles[2].slug}`}>
                        <div
                          className="secondary-article"
                          style={{
                            backgroundImage: `url(${featuredArticles[2].fondo.url})`,
                          }}
                        >
                          <div className="layer">
                            <p>{featuredArticles[2].descripcion}</p>
                          </div>
                          <div className="secondary-article__heading">
                            <h3>
                              <ReactMarkdown
                                source={featuredArticles[2].titulo}
                                escapeHtml={false}
                              />
                            </h3>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="category--v2__secondary-articles">
                <h5>
                  Otros artículos: <span>(desliza hacia abajo)</span>
                </h5>
                {otherArticles
                  .sort(
                    (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
                  )
                  .map((article, id) => {
                    console.log(article);
                    return (
                      <div className="category--v2__secondary-article" key={id}>
                        <Link to={`/blogv2/articulo/${article.slug}`}>
                          <div
                            className="category--v2__secondary-article__media"
                            style={{
                              backgroundImage: `url(${article.fondo.url})`,
                            }}
                          ></div>
                          <h4>{article.titulo}</h4>
                        </Link>
                      </div>
                    );
                  })}

                {/* <div className="category--v2__secondary-article">
                  <Link to="/blogv2/articulo/acero-inoxidable">
                    <div
                      className="category--v2__secondary-article__media"
                      style={{
                        backgroundImage: `url(${B14})`,
                      }}
                    ></div>
                    <h4>¿Para qué se utiliza el alumnio en los camiones?</h4>
                  </Link>
                </div>
                <div className="category--v2__secondary-article">
                  <Link to="/blogv2/articulo/acero-inoxidable">
                    <div
                      className="category--v2__secondary-article__media"
                      style={{
                        backgroundImage: `url(${B15})`,
                      }}
                    ></div>
                    <h4>Acero inoxidable y "la arquitectura moderna"</h4>
                  </Link>
                </div>
                <div className="category--v2__secondary-article">
                  <Link to="/blogv2/articulo/acero-inoxidable">
                    <div
                      className="category--v2__secondary-article__media"
                      style={{
                        backgroundImage: `url(${B15})`,
                      }}
                    ></div>
                    <h4>Acero inoxidable y "la arquitectura moderna"</h4>
                  </Link>
                </div> */}
                {/* <div className="category--v2__slider">
                  <img src={TuboConduit} alt="" />
                </div> */}
              </div>
            </div>
            <Contact />
          </div>
        );
      }}
    </Query>
  );
};

export default NewArticles;
