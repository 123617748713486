import React, { useState } from "react";
import { Link } from "react-router-dom";

// Custom styles
import "./categories.styles.scss";

// Bootstrap
// import Container from "react-bootstrap/Container";

// Custom components
import ReactMarkdown from "react-markdown";
import Query from "../../components/Query";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Queries
import BLOG_QUERY from "../../queries/blog-index/blog";

// Assets
import arrow from "../../assets/right-arrow--white.svg";

const CustomPrevArrow = ({ onClick }) => (
  <button className="custom-prev-arrow" onClick={onClick}>
    <img src={arrow} alt="" />
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button className="custom-next-arrow" onClick={onClick}>
    <img src={arrow} alt="" />
  </button>
);

const CustomFeaturedPrevArrow = ({ onClick }) => (
  <button className="featured-prev-arrow" onClick={onClick}>
    <img src={arrow} alt="" />
  </button>
);

const CustomFeaturedNextArrow = ({ onClick }) => (
  <button className="featured-next-arrow" onClick={onClick}>
    <img src={arrow} alt="" />
  </button>
);

function NewCategories() {
  // const [slides, setSlides] = useState([]);
  const settings = {
    dots: true,
    // dotsClass: "custom-dots",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    // responsive: [
    //   {
    //     breakpoint: 400,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       // dots: true
    //     },
    //   },
    // ],
    // onInit: () => {
    //   setTimeout(() => {
    //     document
    //       .querySelectorAll(".slick-slider .slick-track")
    //       .forEach((item) => {
    //         item.style.transform = "translate3d(0,0,0)";
    //       });
    //   }, 500);
    // },
  };
  const featuredSliderSettings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomFeaturedPrevArrow />,
    nextArrow: <CustomFeaturedNextArrow />,
  };
  function slugToSentence(input) {
    const words = input.split("-");

    if (words.length > 0) {
      words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1); // Capitalize the first word
    }

    const sentence = words.join(" ");
    // const finalSentence = `¿${sentence}?`;
    return sentence;
  }
  return (
    <Query query={BLOG_QUERY}>
      {({ data: { blog } }) => {
        console.log(blog);
        if (blog && blog.Diapositiva) {
          // setSlides(data?.blog?.diapositiva);
          // console.log(slides);
          return (
            <section className="articles-wrapper--v2">
              <div className="articles-wrapper--v2__welcome">
                {/* <Slider {...settings}>
                  {blog.Diapositiva.map((slide, key) => (
                    <div key={key}>
                      <div
                        style={{
                          backgroundImage: `url(${slide.imagen.url})`,
                        }}
                      >
                        <div className="slide-content">
                          <h2>{slide.titulo}</h2>
                          <ReactMarkdown
                            source={slide.descripcion}
                            escapeHtml={false}
                          />
                          <Link to="/blogv2/articulos">Ver más</Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider> */}
                <div
                  style={{
                    backgroundImage: `url(${blog.Diapositiva[0].imagen.url})`,
                  }}
                >
                  <div className="slide-content">
                    <h2>{blog.Diapositiva[0].titulo}</h2>
                    {/* <p>{slide.descripcion}</p> */}
                    <ReactMarkdown
                      source={blog.Diapositiva[0].descripcion}
                      // renderers={renderers}
                      escapeHtml={false}
                    />
                    <Link to="/blogv2/articulos">Ver más</Link>
                  </div>
                </div>
              </div>
              <div className="articles-wrapper--v2__side-bar">
                <div className="articles-wrapper--v2__side-bar__content">
                  {/* <img src={blog.articulos_destacados} alt="" /> */}
                  <Slider {...featuredSliderSettings}>
                    {/*  */}
                    {blog.articulos_destacados.map((article, key) => (
                      <div key={key}>
                        <div className="articles-wrapper--v2__side-bar__media">
                          <img src={article.vista_previa.url} alt="" />
                          <ReactMarkdown
                            source={article.titulo}
                            escapeHtml={false}
                          ></ReactMarkdown>
                        </div>
                        <ReactMarkdown
                          source={article.descripcion}
                          // renderers={renderers}
                          escapeHtml={false}
                        />
                        <Link key={key} to={`blogv2/articulo/${article.slug}`}>
                          Saber más
                        </Link>
                      </div>
                    ))}
                    {/* <div>
                      <h1>hola</h1>
                    </div>
                    <div>
                      <h1>hola</h1>
                    </div> */}
                  </Slider>
                </div>
              </div>
              {blog.ultimo_articulo.slice(0, 3).map((article, key) => (
                <Link
                  key={key}
                  to={`blogv2/articulo/${article.slug}`}
                  style={{
                    backgroundImage: `url(${article.imagen.url})`,
                  }}
                  className={`articles-wrapper--v2__featured articles-wrapper--v2__featured--${key}`}
                >
                  <div className="layer">
                    <h3>{slugToSentence(article.slug)}</h3>
                    <p>{article.descripcion}</p>
                  </div>
                </Link>
              ))}

              {/* <div className="articles-wrapper--v2__featured articles-wrapper--v2__featured--2">
                featured 2
              </div>
              <div className="articles-wrapper--v2__featured articles-wrapper--v2__featured--3">
                featured 3
              </div> */}
            </section>
          );
        } else {
          return (
            <section
              className="articles-wrapper--vw"
              style={{ height: "80vh", backgroundColor: "#003495" }}
            >
              <div className="container h-100 d-flex align-items-center">
                <h2 style={{ color: "#fff" }}>
                  Por el momento no hay contenido
                </h2>
              </div>
            </section>
          );
        }
      }}
    </Query>
  );
}

export default NewCategories;
