import gql from "graphql-tag";

const BLOG_QUERY = gql`
  query BlogIndex {
    blog {
      Diapositiva {
        titulo
        descripcion
        imagen {
          url
        }
      }
      ultimo_articulo {
        slug
        descripcion
        imagen {
          url
        }
      }
      articulos_destacados(sort: "created_at:desc") {
        titulo
        descripcion
        slug
        vista_previa {
          url
        }
      }
    }
  }
`;

export default BLOG_QUERY;
