import gql from "graphql-tag";

const ARTICLE_QUERY = gql`
  query Articles($id: ID!) {
    articles(where: { id: $id }) {
      id
      titulo
      content
      imagen {
        url
      }
      category {
        id
        name
        articles {
          id
          titulo
          content
          descripcion
          imagen {
            url
          }
          category {
            id
            name
          }
        }
      }
      published_at
    }
  }
`;

export default ARTICLE_QUERY;
