import gql from "graphql-tag";

const ARTICLES_QUERY = gql`
  query Articles {
    articles {
      id
      titulo
      content
      prioridad
      slug
      updated_at
      descripcion
      fondo {
        url
      }
    }
  }
`;

export default ARTICLES_QUERY;
